<template>
  <Scrollbar
    thin="thin"
    :style="`width: 100%; height: ${scrollHeight}px;`"
  >
    <v-container class="py-0">
      <v-row>
        <v-col cols="8" class="py-0 px-0">
          <v-card
            rounded="xl"
            :elevation="1"
            class="fill-height"
          >
          <Post
            :post="post"
            :showComments="isActive"
          />
          </v-card>
        </v-col>
        <v-col cols="4" class="py-0">
          <SidebarRight />
        </v-col>
      </v-row>
    </v-container>
  </Scrollbar>
</template>

<script>
  import { mapGetters } from 'vuex'
  import Post from '@components/Community/Post/Post'
  import PostService from '@api/post'
  import { Scrollbar } from '@components/App'
  import SidebarRight from '@components/Community/SidebarRight'

  export default {

    components: {
      Post,
      Scrollbar,
      SidebarRight
    },

    async created() {
      this.postService = PostService(this.user.id)
      this.post = await this.postService.get(this.$route.params.postId)
    },

    data() {
      return {
        post: null,
        postService: null,
      }
    },

    computed: {
      ...mapGetters({
        user: 'user',
        profileId: 'profile/id',
        innerHeight: 'innerHeight',
        isActive: 'community/isActive'
      }),
      scrollHeight() {
        return this.innerHeight - 100
      }
    },
  }
</script>
